<template>
  <b-container>
    <b-row class="mb-3">
      <b-col>
        <b-form-select v-model="selected_sector"
                       :options="sectors"
                       value-field="sector_id"
                       text-field="name"
                       v-on:change="sectorChanged"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-form-select v-model="selected_industry"
                       :options="industries"
                       value-field="industry_id"
                       text-field="name"
                       v-on:change="industryChanged"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col class="text-left">
        <b-row>
          <b-col>
            {{ local_p_e[0] }} &lt; P/E &lt; {{
              local_p_e[1]
            }}
          </b-col>
        </b-row>

        <vue-slider v-model="local_p_e"
                    :enable-cross="false"
                    :min="0"
                    :max="100"
                    v-on:drag-end="p_e_update"></vue-slider>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col class="text-left">
        <b-row>
          <b-col>
            {{ local_p_s[0] }} &lt; P/S &lt; {{
              local_p_s[1]
            }}
          </b-col>
        </b-row>

        <vue-slider v-model="local_p_s"
                    :enable-cross="false"
                    :min="0"
                    :max="100"
                    v-on:drag-end="p_s_update"></vue-slider>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API_BASE_URL,
      filter_data: {},
      local_p_e: [0, 0],
      local_p_s: [0, 0],
      sectors: [],
      industries: [],
      industry_dict: {},
      sector_industries_dict: {}
    }
  },

  computed: {
    selected_sector: {
      get: function () {
        return this.$store.state.selected_sector
      },
      set: function (val) {
        this.$store.commit('set_selected_sector', val)
      }

    },
    selected_industry: {
      get: function () {
        return this.$store.state.selected_industry
      },

      set: function (val) {
        this.$store.commit('set_selected_industry', val)
      }
    }
  },

  mounted() {
    this.local_p_e = this.$store.state.p_e_value
    this.local_p_s = this.$store.state.p_s_value
    this.load_filter();
  },
  methods: {
    p_e_update() {
      this.$store.commit('set_p_e_value', this.local_p_e)
    },
    p_s_update() {
      this.$store.commit('set_p_s_value', this.local_p_s)
    },
    load_filter() {
      let filter_promise = axios.get(this.base_url + '/api/filters',
          {
            withCredentials: true,
            headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')
            },
          })

      return filter_promise.then((resp) => {
        this.filter_data = resp.data
        this.sectors = [{sector_id: null, name: 'Please select a sector'}]

        for (let idx in this.filter_data.sectors) {
          this.sectors.push(this.filter_data.sectors[idx])
        }
        this.industries = [{industry_id: null, name: 'Please select an industry'}]
        for (let idx in this.filter_data.industries) {
          let industry = this.filter_data.industries[idx]
          this.industries.push(industry)
          this.industry_dict[industry.industry_id] = industry
          let sector_id = industry.sector_id
          if (!(sector_id in this.sector_industries_dict)) {
            this.sector_industries_dict[sector_id] = []
          }
          this.sector_industries_dict[sector_id].push(industry)
        }
      }).catch(err => {
        if (401 === err.response.status) {
          this.$router.push('/login');
        }
        console.log(err)
        this.filter_data = {}
      })
    },

    sectorChanged(val) {
      this.industries = this.sector_industries_dict[val]
      this.$store.commit('set_selected_industry', null)
    },
    industryChanged(val) {
      this.$store.commit('set_selected_industry', val)
      let cur_sector = this.$store.state.selected_sector
      let new_sector = this.industry_dict[val].sector_id
      if (cur_sector !== new_sector) {
        this.$store.commit('set_selected_sector', new_sector)
        this.industries = this.sector_industries_dict[this.selected_sector]
      }
    },
  },


}
</script>