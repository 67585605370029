<template>
    <div>
        <zingchart :data="chart_series"
                   v-on:node_click="go_to_stock"></zingchart>
    </div>
</template>

<script>
    import 'zingchart/es6';
    import zingchartVue from 'zingchart-vue';

    export default {
        components: {
            zingchart: zingchartVue,

        },
        created() {
            this.myProvider()
        },
        props: ['fundamental_data', 'x_axis', 'y_axis', 'axis'],

        watch: {
            fundamental_data: function () { // watch it
                this.myProvider()
            },
            x_axis: function () { // watch it
                this.myProvider()
            },
            y_axis: function () { // watch it
                this.myProvider()
            }
        },
        computed: {
            chart_series: function () {
                return this.myProvider()
            }


        },
        mounted() {
          for (let idx in this.axis) {
            let axis = this.axis[idx]
            this.axis_dict[axis.id] = axis.value
          }
        },

        methods: {
            go_to_stock(event) {
                let ticker = this.fundamental_data[event.plotindex].stock
                this.$router.push('/stocks/' + ticker);
            },

            myProvider() {
                var chart_data = {
                    type: 'bubble',
                    height: 500,

                    plotarea: {marginTop: '20px', marginLeft: '100px'},
                    plot: {
                        tooltip: {
                            text: "%t"
                        }
                    },

                    'scale-x': {
                        zooming: true,
                        label: {
                            text: this.axis_dict[this.x_axis]
                        },
                    },
                    'scale-y': {
                        zooming: true,
                        label: {
                            text: this.axis_dict[this.y_axis]
                        },
                    },
                    series: []
                }
                for (let idx in this.fundamental_data) {
                    let series = {
                        values: [],
                        text: this.fundamental_data[idx].stock

                    };
                    series.values.push([this.fundamental_data[idx][this.x_axis], this.fundamental_data[idx][this.y_axis]]);
                    chart_data.series.push(series);
                }
                return chart_data
            }
        },
        data() {
            return {
              axis_dict: {}
            }
        }

    }
</script>

<style>
    .small {
        max-width: 1500px;
        margin-top: -1.1rem !important;
    }

</style>