<template>
    <div id="app">
        <athenanav/>
        <b-row>
            <b-col cols="2">
                <b-container>
                    <b-row>
                        <b-col class="text-left">Budget</b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <b-form-input type="number"
                                          v-model="budget"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-left">Max Price</b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <b-form-input type="number"
                                          v-model="stock_max_total_price"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-left">Sell Price Profit</b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <b-form-input type="number"
                                          v-model="sell_price_profit"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="text-left">Buy Price Diff</b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <b-form-input type="number"
                                          v-model="buy_price_diff"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="text-left">Days For Cost Decreasing
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <b-form-input type="number"
                                          v-model="days_for_cost_decreasing"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-left">Max Buy Count Per Day</b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <b-form-input type="number"
                                          v-model="max_buy_count_per_day"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col align="left">
                            <b-button v-on:click="handleClick">Run</b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
            <b-col cols="10">
                <b-card v-if="loaded">
                    <b-tabs pills card>
                        <b-tab title="Deals" active>
                            <b-card-text>
                                <b-tab title="Deals" active key="key-0"
                                       id="key-0">
                                    <deals-tab :deals="deals"
                                               :profit_in_dollars="profit_in_dollars"
                                               :profit_in_percents="profit_in_percents"/>
                                </b-tab>
                                <b-tab v-for="(tab, index) in strategy_tabs_data"
                                       :key="`key-${index+1}`"
                                       :id="`key-${index+1}`"
                                       :title="tab.ticker">
                                    <stock-tab :ticker="tab.ticker"
                                               :buy_deals="tab.buy"
                                               :sell_deals="tab.sell"></stock-tab>
                                </b-tab>

                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                base_url: process.env.VUE_APP_API_BASE_URL,
                loaded: false,
                profit_in_dollars: 0,
                profit_in_percents: 0,
                budget: 100000,
                stock_max_total_price: 5000,
                sell_price_profit: 10,
                buy_price_diff: 5,
                days_for_cost_decreasing: 30,
                max_buy_count_per_day: 5,
                deals: [],
                deals_by_ticker: {},
                strategy_tabs_data: []
            }
        },
        computed: {
            strategy_tabs() {
                return this.$store.state.strategy_tabs;
            },
        },

        methods: {
            handleClick() {
                this.run_strategy()
            },

            run_strategy() {
                let payload = {
                    strategy: 'simple',
                    budget: parseInt(this.budget),
                    parameters: {
                        stock_max_total_price: parseInt(this.stock_max_total_price),
                        sell_price_profit: parseInt(this.sell_price_profit),
                        buy_price_diff: parseInt(this.buy_price_diff),
                        days_for_cost_decreasing: parseInt(this.days_for_cost_decreasing),
                        max_buy_count_per_day: parseInt(this.max_buy_count_per_day)
                    }
                }
                let stock_promise = axios.post(this.base_url + '/api/strategy', payload,
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return stock_promise.then((resp) => {
                    this.loaded = true
                    this.profit_in_percents = resp.data.profit_in_percents
                    this.profit_in_dollars = resp.data.profit_in_dollars
                    this.deals = resp.data.deals
                    let deals_by_ticker = {}
                    for (var idx in this.deals) {
                        let deal = this.deals[idx];
                        let ticker = deal.ticker;
                        if (!(ticker in deals_by_ticker)) {
                            deals_by_ticker[ticker] = {
                                'ticker': ticker,
                                'buy': [],
                                'sell': []
                            };
                        }
                        if (deal.operation === 'buy') {
                            deals_by_ticker[ticker]['buy'].push(deal);
                        } else {
                            deals_by_ticker[ticker]['sell'].push(deal);
                        }
                    }
                    this.deals_by_ticker = deals_by_ticker
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                })
            },
        },
        watch: {
            strategy_tabs: {
                handler: function () {
                    this.strategy_tabs_data = []
                    for (let idx in this.strategy_tabs) {
                        let ticker = this.strategy_tabs[idx];
                        this.strategy_tabs_data.push(this.deals_by_ticker[ticker])
                    }
                }
            },
        }
    }
</script>