<template>
  <div id="app">
    <athenanav/>
    <b-row>
      <b-col cols="2">
        <stock-filter/>
      </b-col>
      <b-col cols="10">
        <b-card>
          <b-pagination size="md" :total-rows="totalItems"
                        v-model="currentPage"
                        :per-page="perPage"></b-pagination>
          <b-table hover show-empty :items="items" :fields="fields"
                   :current-page="currentPage" :per-page="0"
                   @row-clicked="handleRowClick"></b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API_BASE_URL,
      items: [],
      fields: ['country', 'currency', 'exchange', 'industry', 'sector', 'name', 'ticker'],
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
    }
  },

  computed: {
    selected_sector() {
      return this.$store.state.selected_sector
    },
    selected_industry() {
      return this.$store.state.selected_industry
    },
    p_e_value() {
      return this.$store.state.p_e_value
    },
    p_s_value() {
      return this.$store.state.p_s_value
    }
  },

  mounted() {
    this.load_stocks();
  },
  methods: {
    handleRowClick(item) {
      this.$router.push('/stocks/' + item.ticker);
    },

    load_stocks() {
      let params = {
        page: this.currentPage,
        limit: this.perPage
      }
      if (this.selected_sector) {
        params.sector = this.selected_sector
      }
      if (this.selected_industry) {
        params.industry = this.selected_industry
      }
      if (this.p_e_value[0] != 0 || this.p_e_value[1] != 0) {
        params.p_e_value_min = this.p_e_value[0]
        params.p_e_value_max = this.p_e_value[1]
      }
      if (this.p_s_value[0] != 0 || this.p_s_value[1] != 0) {
        params.p_s_value_min = this.p_s_value[0]
        params.p_s_value_max = this.p_s_value[1]
      }
      let stock_promise = axios.get(this.base_url + '/api/stocks', {
        params: params,
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
        withCredentials: true
      })

      return stock_promise.then((resp) => {
        this.totalItems = resp.data.total
        const items = resp.data.items

        this.items = items;
      }).catch(err => {
        if (401 === err.response.status) {
          this.$router.push('/login');
        }
        console.log(err)
        this.items = []
      })
    },
  },

  watch: {
    selected_sector: {
      handler: function () {
        this.load_stocks().catch(error => {
          console.error(error)
        })
      }
    },
    selected_industry: {
      handler: function () {
        this.load_stocks().catch(error => {
          console.error(error)
        })
      }
    },
    p_e_value: {
      handler: function () {
        this.load_stocks().catch(error => {
          console.error(error)
        })
      },
    },
    p_s_value: {
      handler: function () {
        this.load_stocks().catch(error => {
          console.error(error)
        })
      },
    },
    currentPage: {
      handler: function () {
        this.load_stocks().catch(error => {
          console.error(error)
        })
      }
    }
  }
}
</script>