<template>
    <div id="app">
        <athenanav/>
        <b-row>
            <b-col cols="2">
            </b-col>
            <b-col cols="10">
                <b-card>
                    <b-col cols="5">
                        <b-form-group
                                label="First Name:"
                                label-for="first-name"
                                align="left"
                        >
                            <b-form-input id="first-name"
                                          v-model="current_user.first_name"
                                          placeholder="Enter First Name"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                                label-for="last-name"
                                label="Last Name:"
                                align="left"

                        >
                            <b-form-input id="last-name"
                                          v-model="current_user.last_name"
                                          placeholder="Enter Last Name"
                            ></b-form-input>
                        </b-form-group>

                        <b-link href="/user/2fa">
                            {{ current_user.enabled_2fa ? 'Disable 2FA ' :
                            'Enable 2FA'}}
                        </b-link>

                    </b-col>
                    <b-col align="end" class="mr-3">
                        <b-button variant="primary" @click="update_user">
                            Save
                        </b-button>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        data() {
            return {
                current_user: {},
            }
        },

        mounted() {
            this.load_user();
        },
        methods: {
            update_user() {
                let promise = axios.patch(process.env.VUE_APP_API_BASE_URL + '/api/user', this.current_user,
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return promise.then((resp) => {
                    this.current_user = resp.data

                }).catch(err => {
                    console.log(err)
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                })
            },
            load_user() {
                let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/user',
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return promise.then((resp) => {
                    this.current_user = resp.data;

                }).catch(err => {
                    console.log(err)
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                })
            },
        },
    }
</script>