<template>
    <b-row>
        <b-col align="left">
            <b-row>
                <b-col align="left">
                    Book Value:
                </b-col>
                <b-col align="left">
                    {{data.book_value}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Diluted EPS TTM:
                </b-col>
                <b-col align="left">
                    {{data.diluted_eps_ttm}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    EBITDA:
                </b-col>
                <b-col align="left">
                    {{data.ebitda}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    EPS:
                </b-col>
                <b-col align="left">
                    {{data.eps}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    EV/EBITDA:
                </b-col>
                <b-col align="left">
                    {{data.ev_to_ebitda}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    EV/Revenue:
                </b-col>
                <b-col align="left">
                    {{data.ev_to_revenue}}
                </b-col>
            </b-row>

            <b-row>
                <b-col align="left">
                    Fiscal Year End:
                </b-col>
                <b-col align="left">
                    {{data.fiscal_year_end}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Forward P/E:
                </b-col>
                <b-col align="left">
                    {{data.forward_pe}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Gross Profit TTM:
                </b-col>
                <b-col align="left">
                    {{data.gross_profit_ttm}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Last Split Date:
                </b-col>
                <b-col align="left">
                    {{data.last_split_date}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Last Split Factor:
                </b-col>
                <b-col align="left">
                    {{data.last_split_factor}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Latest Quarter:
                </b-col>
                <b-col align="left">
                    {{data.latest_quarter}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Market Capitalization:
                </b-col>
                <b-col align="left">
                    {{data.market_capitalization}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Operating Margin TTM:
                </b-col>
                <b-col align="left">
                    {{data.operating_margin_ttm}}
                </b-col>
            </b-row>

            <b-row>
                <b-col align="left">
                    Payout Ratio:
                </b-col>
                <b-col align="left">
                    {{data.payout_ratio}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    P/E:
                </b-col>
                <b-col align="left">
                    {{data.pe_ratio}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    P/EG:
                </b-col>
                <b-col align="left">
                    {{data.peg_ratio}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Percent Insiders:
                </b-col>
                <b-col align="left">
                    {{data.percent_insiders}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Percent Institutions:
                </b-col>
                <b-col align="left">
                    {{data.percent_institutions}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Price/Book:
                </b-col>
                <b-col align="left">
                    {{data.price_to_book_ratio}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    P/S TTM:
                </b-col>
                <b-col align="left">
                    {{data.price_to_sales_ratio_ttm}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Profit Margin:
                </b-col>
                <b-col align="left">
                    {{data.profit_margin}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Quarterly Earnings Growth YoY:
                </b-col>
                <b-col align="left">
                    {{data.quarterly_earnings_growth_yoy}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Quarterly Revenue Growth YoY:
                </b-col>
                <b-col align="left">
                    {{data.quarterly_revenue_growth_yoy}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    ROA TTM:
                </b-col>
                <b-col align="left">
                    {{data.return_on_assets_ttm}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    ROE TTM:
                </b-col>
                <b-col align="left">
                    {{data.return_on_equity_ttm}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Revenue Per Share TTM:
                </b-col>
                <b-col align="left">
                    {{data.revenue_per_share_ttm}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Revenue TTM:
                </b-col>
                <b-col align="left">
                    {{data.revenue_ttm}}
                </b-col>
            </b-row>

            <b-row>
                <b-col align="left">
                    Trailing P/E:
                </b-col>
                <b-col align="left">
                    {{data.trailing_pe}}
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>

    export default {

        data() {
            return {}
        },
        props: ['data'],
    }
</script>