<template>
    <b-row>
        <b-col>
            <b-table hover show-empty :items="data" :fields="fields"></b-table>
        </b-col>
    </b-row>
</template>

<script>

    export default {

        data() {
            return {
                fields: ['date', 'form_type', 'title', 'operation_code', 'amount',
                    'price', 'ownership_form']
            }
        },
        props: ['data'],
    }
</script>