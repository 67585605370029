<template>
  <div id="app">
    <athenanav/>

    <b-container>
      <b-row>
        <b-col>
          <b-card no-body>
            <b-tabs pills card>
              <b-tab title="Fundamental" active>
                <b-card-text>
                  <fundamental-tab :data="fundamental_data" v-if="loaded"/>
                </b-card-text>
              </b-tab>
              <b-tab title="Market">
                <b-card-text>
                  <market-tab :data="fundamental_data" v-if="loaded"/>
                </b-card-text>
              </b-tab>
              <b-tab title="Dividends">
                <b-card-text>
                  <dividends-tab :data="fundamental_data" v-if="loaded"/>
                </b-card-text>
              </b-tab>
              <b-tab title="Insiders">
                <b-card-text>
                  <insiders-tab :data="insiders_data" v-if="loaded"/>
                </b-card-text>
              </b-tab>

            </b-tabs>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Price">
            <line-chart-container :ticker="ticker"/>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import LineChartContainer from './prices/ChartContainer'

export default {
  components: {LineChartContainer},
  data() {
    return {
      fundamental_data: {},
      insiders_data: [],
      loaded: false
    }
  },
  props: ['ticker'],

  mounted() {
    this.myProvider(this.ticker);
  },
  methods: {
    load_form4(ticker) {
      let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/stocks/' + ticker + '/form4',
          {
            headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')
            },
            withCredentials: true
          })

      return promise.then((resp) => {
        this.insiders_data = resp.data;
        this.loaded = true;
      }).catch(err => {
        if (401 === err.response.status) {
          this.$router.push('/login');
        }
        console.log(err)
        this.insiders_data = {}
      })
    },
    load_fundamental(ticker) {
      let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/stocks/' + ticker + '/fundamental',
          {
            headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')
            },
            withCredentials: true
          })

      return promise.then((resp) => {
        this.fundamental_data = resp.data;
        return this.load_form4(ticker);
      }).catch(err => {
        if (401 === err.response.status) {
          this.$router.push('/login');
        }
        console.log(err)
        this.fundamental_data = {}
      })
    },

    myProvider(ticker) {
      this.load_fundamental(ticker)
    }
  }

}
</script>