import Vue from 'vue'
import VueRouter from 'vue-router'
import Stocks from '../components/Stocks'
import Stock from '../components/Stock'
import Login from "../components/Login";
import Fundamental from "../components/Fundamental";
import Strategy from "../components/strategy/Strategy";
import Portfolio from "../components/portfolio/Portfolio"
import User from "../components/user/User"
import TwoPhaseScreen from "../components/user/TwoPhaseScreen";
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Login
    },
    {
        path: '/',
        name: 'Stocks',
        component: Stocks
    },
    {
        path: '/strategy',
        name: 'Strategy',
        component: Strategy
    },
    {
        path: '/stocks',
        name: 'Stocks',
        component: Stocks
    },
    {
        path: '/fundamental',
        name: 'Fundamental',
        component: Fundamental
    },
    {
        path: '/stocks/:ticker',
        name: 'Stock',
        component: Stock,
        props: route => ({ticker: route.params.ticker})
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: Portfolio,
    },
    {
        path: '/user',
        name: 'User',
        component: User,
    },
    {
        path: '/user/2fa',
        name: 'TwoPhaseScreen',
        component: TwoPhaseScreen,
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
