<template>
    <div id="app">
        <b-container class="mt-5">
            <b-card cols="4">
                <b-card-text>
                    <b-row align="left" v-if="!show_otp">
                        <b-col>
                            Username
                        </b-col>
                    </b-row>
                    <b-row class="mb-3" v-if="!show_otp">
                        <b-col>
                            <b-form-input v-model="username"
                                          placeholder="Username"
                                          v-on:keyup.enter="login"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row align="left" v-if="!show_otp">
                        <b-col>
                            Password
                        </b-col>
                    </b-row>
                    <b-row class="mb-3" v-if="!show_otp">
                        <b-col>
                            <b-form-input v-model="password" type="password"
                                          placeholder="Password"
                                          v-on:keyup.enter="login"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row align="left" v-if="show_otp">
                        <b-col>
                            Verification Code
                        </b-col>
                    </b-row>
                    <b-row class="mb-3" v-if="show_otp">
                        <b-col>
                            <b-form-input v-model="otp"
                                          placeholder="Verification Code"
                                          v-on:keyup.enter="login"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-alert
                                    :show="dismissCountDown"
                                    variant="danger"
                                    @dismissed="dismissCountDown=0"
                                    @dismiss-count-down="countDownChanged"
                            > {{ show_otp ? 'Wrong Verification Code' :
                                'Wrong Credentials'}}
                            </b-alert>
                            <b-button v-on:click="login">Login</b-button>

                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {

        data() {
            return {
                base_url: process.env.VUE_APP_API_BASE_URL,
                username: null,
                password: null,
                otp: null,
                dismissSecs: 2,
                dismissCountDown: 0,
                showDismissibleAlert: false,
                show_otp: false
            }
        },
        methods: {
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
            showAlert() {
                this.dismissCountDown = this.dismissSecs
            },
            load_user() {
                let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/user',
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return promise.then((resp) => {

                    localStorage.setItem('current_user', resp.data.login);
                    this.$router.push('/stocks');

                }).catch(err => {
                    console.log(err)
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                })
            },

            login() {
                var bodyFormData = new FormData();
                bodyFormData.append('username', this.username);
                bodyFormData.append('password', this.password);
                if (this.otp) {
                    bodyFormData.append('client_secret', this.otp);
                }
                let promise = axios.post(this.base_url + '/api/token', bodyFormData,
                    {withCredentials: true})

                return promise.then((data) => {
                    if (data.data.status === 0) {
                        localStorage.setItem('token', data.data.access_token);
                        return this.load_user();
                    } else {
                        this.show_otp = true;
                    }
                }).catch(error => {
                    this.showAlert()
                    console.log(error)
                })
            }

        }
    }
</script>