<template>
  <div id="app">
    <athenanav/>
    <b-row>
      <b-col cols="2">
        <stock-filter/>
      </b-col>
      <b-col cols="10">
        <b-card>
          <b-row>
            <b-col>
              x-axis:
            </b-col>
            <b-col>

              <b-form-select v-model="x_axis"
                             :options="axis"
                             value-field="id"
                             text-field="value"
              ></b-form-select>
            </b-col>
            <b-col>
              y-axis:
            </b-col>
            <b-col>
              <b-form-select v-model="y_axis"
                             :options="axis"
                             value-field="id"
                             text-field="value"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <bubble-chart-container :x_axis="x_axis"
                                      :y_axis="y_axis"
                                      :axis="axis"
                                      :fundamental_data="items"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import BubbleChartContainer from './prices/BubbleChartContainer'


export default {
  components: {BubbleChartContainer},

  data() {
    return {
      base_url: process.env.VUE_APP_API_BASE_URL,
      items: [],
      axis: [
        {"id": "book_value", "value": "Book Value"},
        {"id": "beta", "value": "Beta"},
        {"id": "diluted_eps_ttm", "value": "Diluted EPS TTM"},
        {"id": "ebitda", "value": "EBITDA"},
        {"id": "eps", "value": "EPS"},
        {"id": "ev_to_ebitda", "value": "EV/EBITDA"},
        {"id": "ev_to_revenue", "value": "EV/Revenue"},
        {"id": "forward_pe", "value": "Forward P/E"},
        {"id": "gross_profit_ttm", "value": "Gross Profit TTM"},
        {
          "id": "market_capitalization",
          "value": "Market Capitalization"
        },
        {
          "id": "operating_margin_ttm",
          "value": "Operating Margin TTM"
        },
        {"id": "pe_ratio", "value": "P/E"},
        {"id": "peg_ratio", "value": "P/EG"},
        {"id": "percent_insiders", "value": "Percent Insiders"},
        {
          "id": "percent_institutions",
          "value": "Percent Institutions"
        },
        {"id": "price_to_book_ratio", "value": "Price/Book"},
        {"id": "price_to_sales_ratio_ttm", "value": "P/S TTM"},
        {"id": "profit_margin", "value": "Profit Margin"},
        {
          "id": "quarterly_earnings_growth_yoy",
          "value": "Quarterly Earnings Growth YoY"
        },
        {
          "id": "quarterly_revenue_growth_yoy",
          "value": "Quarterly Revenue Growth YoY"
        },
        {"id": "return_on_assets_ttm", "value": "ROA TTM"},

        {"id": "return_on_equity_ttm", "value": "ROE TTM"},
        {"id": "revenue_per_share_ttm", "value": "Revenue/Share"},
        {"id": "revenue_ttm", "value": "Revenue TTM"},
        {"id": "shares_float", "value": "Shares Float"},
        {
          "id": "shares_outstanding",
          "value": "Shares Outstanding"
        },
        {"id": "shares_short", "value": "Shares Short"},
        {
          "id": "shares_short_prior_month",
          "value": "Shares Short Prior Month"
        },
        {
          "id": "short_percent_float",
          "value": "Short Percent Float"
        },
        {
          "id": "short_percent_outstanding",
          "value": "Short Percent Outstanding"
        },
        {"id": "short_ratio", "value": "Short Ratio"},
        {"id": "trailing_pe", "value": "Trailing P/E"},

      ],
      x_axis: 'trailing_pe',
      y_axis: 'eps'
    }
  },

  computed: {
    selected_sector() {
      return this.$store.state.selected_sector
    },
    selected_industry() {
      return this.$store.state.selected_industry
    },
    p_e_value() {
      return this.$store.state.p_e_value
    },
    p_s_value() {
      return this.$store.state.p_s_value
    }
  },

  mounted() {
    if (this.selected_sector || this.selected_industry || this.p_e_value[0]
        || this.p_e_value[1] || this.p_s_value[0] || this.p_s_value[1]) {
      this.load_fundamental();
    }
  },
  methods: {
    load_fundamental() {
      let params = {
        sector: this.selected_sector,
        industry: this.selected_industry
      }
      if (this.p_e_value[0] != 0 || this.p_e_value[1] != 0) {
        params.p_e_value_min = this.p_e_value[0]
        params.p_e_value_max = this.p_e_value[1]
      }
      if (this.p_s_value[0] != 0 || this.p_s_value[1] != 0) {
        params.p_s_value_min = this.p_s_value[0]
        params.p_s_value_max = this.p_s_value[1]
      }
      let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/fundamental',
          {
            params: params,
            headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')
            },
            withCredentials: true
          })

      return promise.then((resp) => {
        this.items = resp.data

      }).catch(err => {
        console.log(err)
        if (401 === err.response.status) {
          this.$router.push('/login');
        }
        this.items = [];
      })
    },
  },
  watch: {
    selected_sector: {
      handler: function () {
        this.load_fundamental().catch(error => {
          console.error(error)
        })
      }
    },
    selected_industry: {
      handler: function () {
        this.load_fundamental().catch(error => {
          console.error(error)
        })
      }
    },
    p_e_value: {
      handler: function () {
        this.load_fundamental().catch(error => {
          console.error(error)
        })
      },
    },
    p_s_value: {
      handler: function () {
        this.load_fundamental().catch(error => {
          console.error(error)
        })
      },
    }
  }

}
</script>