<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-input v-model="name" placeholder="Enter portfolio name"></b-form-input>
      </b-col>
      <b-col>
        <b-button variant="primary">Save</b-button><b-button class="ml-1" variant="danger">Reset</b-button>
      </b-col>
    </b-row>
    <b-row></b-row>
  </b-container>
</template>

<script>

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API_BASE_URL,
      name: null
    }
  },


  mounted() {
  },
  methods: {}
}
</script>