import Vue from 'vue'
import App from './App.vue'
import router from './router'
import FundamentalTab from "./components/FundamentalTab";
import DividendsTab from "./components/DividendsTab";
import MarketTab from "./components/MarketTab";
import InsidersTab from "./components/InsidersTab"
import VueSlider from 'vue-slider-component'
import AthenaNav from "./components/AthenaNav";
import StockFilter from "./components/StockFilter"
import DealsTab from "./components/strategy/DealsTab"
import StockTab from "./components/strategy/StockTab"
import PortfolioItem from "./components/portfolio/PortfolioItem";
import 'vue-slider-component/theme/default.css'
import Vuex from 'vuex'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.component('fundamental-tab', FundamentalTab);
Vue.component('market-tab', MarketTab);
Vue.component('dividends-tab', DividendsTab)
Vue.component('insiders-tab', InsidersTab)
Vue.component('VueSlider', VueSlider)
Vue.component('athenanav', AthenaNav)
Vue.component('stock-filter', StockFilter)
Vue.component('deals-tab', DealsTab)
Vue.component('stock-tab', StockTab)
Vue.component('portfolio-item', PortfolioItem)

const store = new Vuex.Store({
    state: {
        selected_sector: null,
        selected_industry: null,
        p_e_value: [0, 0],
        p_s_value: [0, 0],
        strategy_tabs: []
    },
    mutations: {
        set_selected_sector(state, new_sector) {
            state.selected_sector = new_sector;
        },
        set_selected_industry(state, new_industry) {
            state.selected_industry = new_industry;
        },
        set_p_e_value(state, new_p_e_value) {
            state.p_e_value = new_p_e_value;
        },
        set_p_s_value(state, new_p_s_value) {
            state.p_s_value = new_p_s_value;
        },
        set_strategy_tabs(state, new_strategy_tab) {
            state.strategy_tabs.push(new_strategy_tab);
        }
    }
})

new Vue({
    router,
    store: store,
    render: h => h(App)
}).$mount('#app')
