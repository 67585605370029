<template>
    <div id="app">
        <athenanav/>
        <b-row>

            <b-col cols="12" align="center">
                <b-card>

                    <vue-qrcode v-if="!current_user.enabled_2fa"
                                :value="otp_data.url" :width="width"/>
                    <b-form-group
                            label-for="first-name"
                            align="left"
                            class="mt-2"
                    >
                        <b-form-input id="first-name"
                                      v-model="verification_code"
                                      placeholder="Enter Verification Code"
                                      v-on:keyup.enter="update_2fa"
                        ></b-form-input>
                    </b-form-group>
                    <b-col>
                        <b-button variant="primary" @click="update_2fa">
                            Submit
                        </b-button>
                        <b-button variant="secondary" @click="cancel"
                                  class="ml-2">
                            Cancel
                        </b-button>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from 'axios';
    import VueQrcode from 'vue-qrcode'

    export default {
        components: {
            VueQrcode,
        },
        data() {
            return {
                current_user: {},
                otp_data: {},
                width: 400,
                verification_code: null
            }
        },

        mounted() {
            this.load_user();
        },
        methods: {
            cancel() {
                this.$router.push('/user')
            },
            update_2fa() {
                let promise = axios.post(process.env.VUE_APP_API_BASE_URL + '/api/user/2fa', {code: this.verification_code},
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    });

                return promise.then(() => {
                    this.$router.push('/user')

                }).catch(err => {
                    console.log(err);
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                })
            },
            get_2fa() {
                let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/user/2fa',
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    });

                return promise.then((resp) => {
                    this.otp_data = resp.data

                }).catch(err => {
                    console.log(err);
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                })
            },

            load_user() {
                let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/user',
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    });

                return promise.then((resp) => {
                    this.current_user = resp.data;
                    if (!this.current_user.enabled_2fa) {
                        return this.get_2fa()
                    }

                }).catch(err => {
                    console.log(err);
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                })
            },
        },
    }
</script>