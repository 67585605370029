<template>
    <b-row>
        <b-col align="left">
            <b-row>
                <b-col align="left">
                    Dividend Date:
                </b-col>
                <b-col align="left">
                    {{data.dividend_date}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Dividend Per Share:
                </b-col>
                <b-col align="left">
                    {{data.dividend_per_share}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Dividend Yield:
                </b-col>
                <b-col align="left">
                    {{data.dividend_yield}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Ex. Dividend Date:
                </b-col>
                <b-col align="left">
                    {{data.ex_dividend_date}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Forward Annual Dividend Rate:
                </b-col>
                <b-col align="left">
                    {{data.forward_annual_dividend_rate}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Forward Annual Dividend Yield:
                </b-col>
                <b-col align="left">
                    {{data.forward_annual_dividend_yield}}
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>

    export default {

        data() {
            return {}
        },
        props: ['data'],
    }
</script>