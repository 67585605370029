<template>
    <div class="small">
        <zingchart :data="chartData" v-if="loaded"></zingchart>
    </div>
</template>

<script>
    import axios from 'axios';
    import 'zingchart/es6';
    import zingchartVue from 'zingchart-vue';

    export default {
        components: {
            zingchart: zingchartVue,

        },
        props: ['ticker'],
        created() {
            this.myProvider()
        },
        methods: {
            myProvider() {
                // Here we don't set isBusy prop, so busy state will be
                // handled by table itself
                // this.isBusy = true
                let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/stocks/' + this.ticker + '/prices',
                    {
                        withCredentials: true,
                        headers: {
                          "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                    })

                return promise.then((resp) => {
                    const items = []
                    for (var idx in resp.data) {
                        items.push([resp.data[idx].date, resp.data[idx].close_price]);
                    }
                    this.chartData.series[0].values = items;
                    this.loaded = true;
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                    this.chartData.series[0].values = [];
                })
            }
        },
        data() {
            return {
                loaded: false,
                chartData: {
                    type: 'line',
                    height: 500,
                    plotarea: {marginTop: '20px'},


                    'crosshair-x': {},
                    'scale-x': {
                        zooming: true,

                        transform: {
                            type: "date",
                            all: "%Y-%mm-%dd"
                        }
                    },
                    'scale-y': {
                        zooming: false
                    },
                    series: [{
                        values: [],
                        text: "Price"

                    }]
                }
            }
        }

    }
</script>

<style>
    .small {
        max-width: 600px;
        margin-top: -1.1rem !important;
    }

</style>