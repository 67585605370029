<template>
    <b-row>
        <b-col align="left">

            <b-row>
                <b-col align="left">
                    Analyst Target Price:
                </b-col>
                <b-col align="left">
                    {{data.analyst_target_price}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Beta:
                </b-col>
                <b-col align="left">
                    {{data.beta}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    200 Day MA:
                </b-col>
                <b-col align="left">
                    {{data.price_200_day_moving_average}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    50 Day MA:
                </b-col>
                <b-col align="left">
                    {{data.price_50_day_moving_average}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    52 Week High:
                </b-col>
                <b-col align="left">
                    {{data.price_52_week_high}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    52 Week Low:
                </b-col>
                <b-col align="left">
                    {{data.price_52_week_low}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Shares Float:
                </b-col>
                <b-col align="left">
                    {{data.shares_float}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Shares Outstanding:
                </b-col>
                <b-col align="left">
                    {{data.shares_outstanding}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Shares Short:
                </b-col>
                <b-col align="left">
                    {{data.shares_short}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Shares Short Prior Month:
                </b-col>
                <b-col align="left">
                    {{data.shares_short_prior_month}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Short Percent Float:
                </b-col>
                <b-col align="left">
                    {{data.short_percent_float}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Short Percent Outstanding:
                </b-col>
                <b-col align="left">
                    {{data.short_percent_outstanding}}
                </b-col>
            </b-row>
            <b-row>
                <b-col align="left">
                    Short Ratio:
                </b-col>
                <b-col align="left">
                    {{data.short_ratio}}
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>

    export default {
        data() {
            return {}
        },
        props: ['data'],
    }
</script>