<template>
  <div>
    <b-row>
      <b-col>
        <deals-chart-container :ticker="ticker" :buy_deals="buy_deals"
                               :sell_deals="sell_deals" v-if="ticker"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DealsChartContainer from './DealsChart'

export default {
  props: ['ticker', 'buy_deals', 'sell_deals'],
  components: {DealsChartContainer},
}
</script>