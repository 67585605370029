<template>
  <div id="nav">
    <b-row>
      <b-col>
        <router-link to="/user">{{ current_user }}</router-link>
      </b-col>
      <b-col cols="10">
        <router-link to="/fundamental">Fundamental</router-link>
        |
        <router-link to="/stocks">Stocks</router-link>
        |
        <router-link to="/strategy">Strategy</router-link>
        |
        <router-link to="/portfolio">Portfolio</router-link>
      </b-col>
      <b-col>
        <b-button v-on:click="logout">Logout</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  computed: {
    current_user() {
      return localStorage.getItem('current_user')
    },
  },
  methods: {
    logout() {
      localStorage.clear()
      this.$router.push('/login');
    }
  }
}
</script>
<style>

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

