<template>
  <b-container>
    <b-row class="mb-3">
      <b-col>
        <b-form-input type="text" v-model="ticker_filter" placeholder="Ticker"></b-form-input>
      </b-col>
      <b-col>Profit, $: {{ profit_in_dollars }}</b-col>
      <b-col>Profit, %: {{ profit_in_percents }}</b-col>
    </b-row>
    <b-row>
      <b-pagination size="md" :total-rows="total_items"
                    v-model="current_page"
                    :per-page="20"></b-pagination>
      <b-table hover show-empty :items="deals" :fields="fields" :per-page="20" :current-page="current_page"
               :filter="ticker_filter" @row-clicked="handleRowClick" @filtered="handleFiltered"
               :tbody-tr-class="rowClass"/>
    </b-row>
  </b-container>
</template>

<script>

export default {
  props: ['deals', 'profit_in_dollars', 'profit_in_percents'],

  data() {
    return {
      current_page: 1,
      total_items: this.deals.length,
      fields: [{key: 'date'}, {key: 'ticker'}, {key: 'operation'}, {key: 'amount'}, {key: 'price'},
        {key: 'profit_in_dollars', label: 'Profit, $'}, {key: 'profit_in_percents', label: 'Profit, %'}],
      ticker_filter: null,
    }
  },
  methods: {
    handleFiltered(items) {
      this.total_items = items.length
    },
    handleRowClick(item) {
      this.$store.commit('set_strategy_tabs', item.ticker);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.profit_in_percents < 0) {
        return 'table-danger'
      } else if (item.profit_in_percents > 0) {
        return 'table-success'
      } else if (item.profit_in_percents == 0) {
        return 'table-secondary'
      }
    }
  }
}
</script>