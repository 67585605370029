<template>
  <div>
    <zingchart :data="chartData" v-if="loaded"></zingchart>
  </div>
</template>

<script>
import axios from 'axios';
import 'zingchart/es6';
import zingchartVue from 'zingchart-vue';

export default {
  components: {
    zingchart: zingchartVue,

  },
  props: ['ticker', 'buy_deals', 'sell_deals'],
  created() {
    if (this.ticker) {
      this.myProvider()
    }
  },
  methods: {
    toDate(date) {
      let  myDate = date.split("-");
      var newDate = new Date( myDate[0], myDate[1] - 1, myDate[2]);
      return newDate.getTime();
    },

    myProvider() {
      var buy_items = []
      for (var idx in this.buy_deals) {
        var deal = this.buy_deals[idx];
        buy_items.push([this.toDate(deal.date), parseFloat(deal.price)])
      }
      this.chartData.series[1].values = buy_items;

      var sell_items = []
      for (idx in this.sell_deals) {
        deal = this.sell_deals[idx];
        sell_items.push([this.toDate(deal.date), parseFloat(deal.price)])
      }
      this.chartData.series[2].values = sell_items;


      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      // this.isBusy = true
      let promise = axios.get(process.env.VUE_APP_API_BASE_URL + '/api/stocks/' + this.ticker + '/prices',
          {
            withCredentials: true,
            headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')
            },
          })

      return promise.then((resp) => {
        const items = []
        for (var idx in resp.data) {
          items.push([resp.data[idx].date, resp.data[idx].close_price]);
        }
        this.chartData.series[0].values = items;
        this.loaded = true;
      }).catch(err => {
        if (401 === err.response.status) {
          this.$router.push('/login');
        }
        console.log(err)
        this.chartData.series[0].values = [];
      })
    }
  },
  data() {
    return {
      loaded: false,
      chartData: {
        type: 'mixed',
        height: 500,
        legend: {
    layout: "5x1",
    position: "97% 73%",
    item: {
      'font-color': "brown",
      'font-family': "Georgia"
    },
    'background-color': "white",
    alpha: 0.5,
    'border-color': "none",
    shadow: false,
    marker: {
      type: "inherit"
    }
  },
        plotarea: {marginTop: '20px'},

        'scale-x': {
          zooming: true,

          transform: {
            type: "date",
            all: "%Y-%mm-%dd"
          }
        },
        'scale-y': {
          zooming: false
        },
        series: [{
          type: "line",
          values: [],
          text: "Price",

        },
        {
          type: "scatter",
          values: [],
          text: "Buy",
          marker: {
            'background-color': 'green'
          }

        },
        {
          type: "scatter",
          values: [],
          text: "Sell",
          marker: {
            'background-color': 'red'
          }

        }]
      }
    }
  }

}
</script>

<style>
.small {
  max-width: 600px;
  margin-top: -1.1rem !important;
}

</style>