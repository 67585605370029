<template>
    <div id="app">
        <b-modal id="my-modal" v-on:ok="savePortfolioStock"
                 v-on:cancel="deletePortfolioStock" :cancel-title="this.active_portfolio_stock.owned_stock_id ? 'Delete' : 'Cancel'"
                 :cancel-variant="this.active_portfolio_stock.owned_stock_id ? 'danger' : 'secondary'">
            <b-form-group
                    description="Ticker"
                    label-for="stock-name"
            >

                <b-form-input id="stock-name"
                              v-model="active_portfolio_stock.ticker"
                              placeholder="Enter ticker"
                              list="tickers-id"></b-form-input>
                <datalist id="tickers-id">
                    <option v-for="ticker in tickers" :key="ticker">{{ ticker
                        }}
                    </option>
                </datalist>
            </b-form-group>
            <b-form-group
                    description="Price"
                    label-for="stock-price"
            >
                <b-form-input id="stock-price"
                              v-model="active_portfolio_stock.price"
                              placeholder="Enter price"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                    description="Amount"
                    label-for="stock-amount"
            >
                <b-form-input id="stock-amount"
                              v-model="active_portfolio_stock.amount"
                              placeholder="Enter amount"
                              type="number"></b-form-input>
            </b-form-group>
        </b-modal>
        <athenanav/>
        <b-row>
            <b-col cols="2">

                <b-row>
                    <b-col>
                        <b-list-group>
                            <b-list-group-item button
                                               v-for="portfolio in portfolios"
                                               :key="portfolio.portfolio_id"
                                               v-on:click="selectPortfolio(portfolio)"
                                               :variant="portfolio.portfolio_id === active_portfolio_id ? 'secondary' : ''">
                                {{ portfolio.name }}
                            </b-list-group-item>
                            <b-list-group-item button key="create"
                                               v-on:click="newPortfolio"
                                               variant="primary"
                            >Add
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="10" v-if="active_portfolio">
                <b-row>
                    <b-col align="end" class="mr-3">
                        <b-button variant="primary" @click="savePortfolio">
                            Save
                        </b-button>
                        <b-button class="ml-2" variant="danger"
                                  @click="deletePortfolio">Delete
                        </b-button>
                    </b-col>
                </b-row>
                <b-row align='start' class="mt-3">
                    <b-col>
                        <b-form-group
                                label="Name:"
                                label-for="portfolio-name"
                        >
                            <b-form-input id="portfolio-name"
                                          v-model="active_portfolio.name"
                                          placeholder="Enter portfolio name"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="active_portfolio_id">
                    <b-col align="end" class="mr-3">
                        <b-button v-on:click="newPortfolioStock">Add</b-button>
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="active_portfolio_id">
                    <b-col>
                        <b-table hover show-empty
                                 :items="active_portfolio_stocks"
                                 :fields="fields"
                                 select-mode="single"
                                 @row-clicked="editPortfolioStock"
                        ></b-table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                base_url: process.env.VUE_APP_API_BASE_URL,
                portfolios: [],
                active_portfolio_id: null,
                active_portfolio: null,
                active_portfolio_stocks: [],
                fields: [{key: 'ticker'}, {key: 'amount'}, {key: 'price'}],
                active_portfolio_stock: {},
                stocks_map: {},
                tickers: []
            }
        },


        mounted() {
            this.load_portfolio();
            this.load_stocks();
        },
        methods: {
            editPortfolioStock(stock) {
                console.log(stock)
                this.active_portfolio_stock = stock;
                this.$bvModal.show('my-modal')
            },
            deletePortfolioStock() {
                if (this.active_portfolio_stock.owned_stock_id) {
                    let save_promise = axios.delete(this.base_url + '/api/portfolio/' + this.active_portfolio.portfolio_id + '/stocks/' +
                        this.active_portfolio_stock.owned_stock_id,
                        {
                            headers: {
                                "Authorization": "Bearer " + localStorage.getItem('token')
                            },
                            withCredentials: true
                        }
                    )

                    return save_promise.then(() => {
                        this.active_portfolio_stock = {};
                        return this.load_portfolio_stocks();
                    }).catch(err => {
                        if (401 === err.response.status) {
                            this.$router.push('/login');
                        }
                        console.log(err)
                    })
                }
            },
            newPortfolioStock() {
                this.active_portfolio_stock = {};
                this.$bvModal.show('my-modal')
            },
            createPortfolioStock() {
                this.active_portfolio_stock.amount = parseInt(this.active_portfolio_stock.amount);
                this.active_portfolio_stock.price = parseFloat(this.active_portfolio_stock.price);
                this.active_portfolio_stock.stock_id = this.stocks_map[this.active_portfolio_stock.ticker]
                let save_promise = axios.post(this.base_url + '/api/portfolio/' + this.active_portfolio_id + '/stocks', this.active_portfolio_stock,
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return save_promise.then(() => {
                    this.active_portfolio_stock = {}
                    return this.load_portfolio_stocks();
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                })
            },

            updatePortfolioStock() {
                this.active_portfolio_stock.amount = parseInt(this.active_portfolio_stock.amount);
                this.active_portfolio_stock.price = parseFloat(this.active_portfolio_stock.price);
                this.active_portfolio_stock.stock_id = this.stocks_map[this.active_portfolio_stock.ticker]
                let save_promise = axios.patch(this.base_url + '/api/portfolio/' + this.active_portfolio_id + '/stocks/' + this.active_portfolio_stock.owned_stock_id, this.active_portfolio_stock,
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return save_promise.then(() => {
                    this.active_portfolio_stock = {}
                    return this.load_portfolio_stocks();
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                })
            },

            savePortfolioStock() {
                if (this.active_portfolio_stock.owned_stock_id) {
                    this.updatePortfolioStock()
                } else {
                    this.createPortfolioStock()
                }
            },
            savePortfolio() {
                if (this.active_portfolio.portfolio_id) {
                    this.updatePortfolio()
                } else {
                    this.createPortfolio()
                }
            },
            createPortfolio() {
                let save_promise = axios.post(this.base_url + '/api/portfolio', this.active_portfolio,
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return save_promise.then((resp) => {
                    this.active_portfolio_id = resp.data.portfolio_id
                    return this.load_portfolio()
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                })
            },

            updatePortfolio() {
                let save_promise = axios.patch(this.base_url + '/api/portfolio/' + this.active_portfolio.portfolio_id, this.active_portfolio,
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return save_promise.then(() => {
                    return this.load_portfolio()
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                })
            },

            deletePortfolio() {
                let save_promise = axios.delete(this.base_url + '/api/portfolio/' + this.active_portfolio.portfolio_id,
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        },
                        withCredentials: true
                    })

                return save_promise.then(() => {
                    this.active_portfolio = null;
                    this.active_portfolio_id = null;

                    return this.load_portfolio()
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                })
            },

            newPortfolio() {
                this.active_portfolio_id = null;
                this.active_portfolio = {};
                this.active_portfolio_stocks = [];
                this.active_portfolio_stock = {};
            },
            selectPortfolio(active_portfolio) {
                this.active_portfolio_id = active_portfolio.portfolio_id
                this.active_portfolio = active_portfolio
                this.load_portfolio_stocks()
            },

            load_portfolio() {
                let portfolio_promise = axios.get(this.base_url + '/api/portfolio', {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('token')
                    },
                    withCredentials: true
                })

                return portfolio_promise.then((resp) => {
                    this.portfolios = resp.data
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                    this.portfolios = []
                })
            },

            load_portfolio_stocks() {
                let portfolio_stocks_promise = axios.get(this.base_url + '/api/portfolio/' + this.active_portfolio_id + '/stocks', {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('token')
                    },
                    withCredentials: true
                })

                return portfolio_stocks_promise.then((resp) => {
                    this.active_portfolio_stocks = resp.data
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                    this.active_portfolio_stocks = []
                })
            },
            load_stocks() {
                let stock_promise = axios.get(this.base_url + '/api/stocks', {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('token')
                    },
                    withCredentials: true
                })

                return stock_promise.then((resp) => {
                    this.totalItems = resp.data.total
                    const items = resp.data.items
                    for (var idx in items) {
                        this.stocks_map[items[idx].ticker] = items[idx].stock_id;
                        this.tickers.push(items[idx].ticker)
                    }
                }).catch(err => {
                    if (401 === err.response.status) {
                        this.$router.push('/login');
                    }
                    console.log(err)
                })
            },
        },

    }
</script>